import SearchLessons from "../../components/Lesson/SearchLessons";

export default function SearchPage() {

  return (
    <div className="container">
      <SearchLessons
      />
    </div>
  );
}