import { Cart } from "../../context/CartContext";
import "./lessoncart.css";
import Card from "../../shared/card/Card";
import Lesson from "../lesson/Lesson";

// const LessonCart = () => {
//   const { cartLessons, calculateTotalPrice } =
//     Cart();


    //* {cartLessons.length > 0 ? ( //*

    export default function LessonCart() {
    
  return (
    <Card className="container">
      <h1>Cart coming soon!</h1>
    {/* //   {cartLessons.length ? (
    //     <div>
    //       {cartLessons.map((lesson) => (
    //       <Lesson
    //         key={lesson.id}
    //         id={lesson.id}
    //         category={lesson.category}
    //         price={lesson.price}
    //         lesson={lesson}
    //       />
    //       ))}

    //       <div className="bottom">

    //           <h3 className="total">Total: ${calculateTotalPrice()}</h3>

    //       </div>

    //     </div>
    //   ) : (
    //     <h2 className="empty">Your cart is empty!</h2>
    //   )} */}

    </Card>
  );
}

