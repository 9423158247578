import React from "react";
import Card from "../shared/card/Card";


export default function AllLessonsBox() {

    return (
        <Card>
            <h2>All Lessons Scheduled:</h2>
        </Card> 
        )
    }