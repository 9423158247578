import LessonCart from "../../components/Lesson/lessoncart/LessonCart";
import "./cartpage.css";

export default function CartPage() {

  return (
    <div className="container">
      <LessonCart />
      
    </div>
  );
  }