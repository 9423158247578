import "./detailspage.css";
import LessonDetails from "../../components/Lesson/lessondetails/LessonDetails";

export default function DetailsPage() {

  return (
    <div className="container">
      <LessonDetails />
      
    </div>
  );
  }